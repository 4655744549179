// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";

// Material Kit 2 React components
import Contenedor from "components/Contenedor";

// Material Kit 2 React examples
import RotatingCard from "../../components/RotatingCard/index";
import RotatingCardFront from "../../components/RotatingCard/RotatingCardFront";
import RotatingCardBack from "../../components/RotatingCard/RotatingCardBack";

// eslint-disable-next-line react/prop-types
function Information({ TituloF, ImgF, TituloT, Descripcion, ImgT, ruta, xs, sm, md, lg, text }) {
  return (
    <Card
      sx={{
        height: "100%",
        background: "transparent",
        boxShadow: "none",
        overflow: "visible",
      }}
    >
      <Contenedor component="section" position="relative" py={1} my={1}>
        <Container>
          <Grid justifyContent="center" alignItems="center" lg={12}>
            <Grid item xs={xs} sm={sm} md={md} lg={lg}>
              <RotatingCard>
                <RotatingCardFront image={ImgF} title={TituloF} />
                <RotatingCardBack
                  image={ImgT}
                  title={TituloT}
                  description={Descripcion}
                  action={{
                    type: "internal",
                    route: ruta,
                    label: text,
                  }}
                />
              </RotatingCard>
            </Grid>
          </Grid>
        </Container>
      </Contenedor>
    </Card>
  );
}

export default Information;
