import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import SendIcon from "@mui/icons-material/Send";
import TextSection from "components/TextSection";
import ReCAPTCHA from "react-google-recaptcha";
import Contenedor from "../../../components/Contenedor";
import Button from "../../../components/Button";
import Input from "../../../components/Input";

async function sendDataWebpage(datos) {
  try {
    const resp = await fetch(`https://purpapi.ddns.net/contacto/contactopurp`, {
      method: "POST",
      body: JSON.stringify(datos),
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    const respuesta = resp.json();
    return respuesta;
  } catch (err) {
    if (err.name === "AbortError") {
      return {
        message: "OK",
        datos: "Tu información no se ha enviado",
      };
    }
    return { message: "ERROR", datos: err };
  }
}

function Contacto({
  /* eslint-disable react/prop-types */
  titulo,
  nombre,
  asunto,
  telefono,
  correo,
  mensaje,
  enviar,
  phNombre,
  phAsunto,
  phCorreo,
  phTelefono,
  phMensaje,
}) {
  const [formData, setFormData] = useState({
    name: "",
    asunto: "",
    email: "",
    telefono: "",
    mensaje: "",
  });
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      !formData.name ||
      !formData.asunto ||
      !formData.email ||
      !formData.telefono ||
      !formData.mensaje
    ) {
      // eslint-disable-next-line no-alert
      alert("Por favor, complete todos los campos antes de enviar el formulario.");
      return;
    }

    if (!recaptchaValue) {
      // eslint-disable-next-line no-alert
      alert("Por favor, complete la verificación reCAPTCHA.");
      return;
    }

    const datos = {
      id: "principal",
      name: formData.name,
      asunto: formData.asunto,
      email: formData.email,
      telefono: formData.telefono,
      mensaje: formData.mensaje,
    };

    const response = await sendDataWebpage(datos);

    if (response.message === "OK") {
      // eslint-disable-next-line no-alert
      alert("Tu mensaje ha sido enviado correctamente.");
    } else {
      // eslint-disable-next-line no-alert
      alert(response.datos != null ? response.datos : "Ocurrió un error al enviar el mensaje");
    }

    setFormData({
      name: "",
      asunto: "",
      email: "",
      telefono: "",
      mensaje: "",
    });
  };

  return (
    <Contenedor bgColor="white">
      <Grid
        container
        item
        xs={12}
        lg={12}
        justifyContent="center"
        alignItems="center"
        sx={{ mx: "auto" }}
      >
        <TextSection variant="h3" sx={{ color: "#5B524D" }} textAlign="center" mt={10} mb={2}>
          {titulo}
        </TextSection>
      </Grid>
      <Grid item xs={12} sm={12} lg={12}>
        <Contenedor component="form" id="form" p={2} method="post" onSubmit={handleSubmit}>
          <Contenedor pt={2} pb={2} px={{ xs: 6, md: 12, lg: 12 }}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} sm={12} md={6} lg={5} mt={0}>
                <Input
                  name="name"
                  label={nombre}
                  placeholder={phNombre}
                  fullWidth
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={4} mt={{ xs: -1.5, lg: 0 }} mb={0}>
                <Input
                  name="asunto"
                  label={asunto}
                  placeholder={phAsunto}
                  fullWidth
                  value={formData.asunto}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={5} mt={-1.5}>
                <Input
                  name="email"
                  type="email"
                  label={correo}
                  placeholder={phCorreo}
                  fullWidth
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={4} mt={-1.5}>
                <Input
                  name="telefono"
                  label={telefono}
                  placeholder={phTelefono}
                  fullWidth
                  value={formData.telefono}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={11} lg={9} mt={-1.5}>
                <Input
                  name="mensaje"
                  label={mensaje}
                  placeholder={phMensaje}
                  fullWidth
                  multiline
                  rows={6}
                  value={formData.mensaje}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid
                item
                xs={10}
                md={11}
                lg={9}
                mt={-1.5}
                sx={{
                  justifyContent: {
                    xs: "center",
                    lg: "flex-end",
                  },
                }}
                container
                textAlign="right"
              >
                <Contenedor mt={-1.5}>
                  <ReCAPTCHA
                    sitekey="6Lf0uvgfAAAAAPUOjQDU3QdAhUMS-jYKVv01MI9t"
                    onChange={handleRecaptchaChange}
                    style={{ transform: "scale(0.75)" }}
                  />
                </Contenedor>

                <Contenedor>
                  <Button
                    color="light"
                    variant="gradient"
                    endIcon={<SendIcon />}
                    size="medium"
                    type="submit"
                  >
                    {enviar}
                  </Button>
                </Contenedor>
              </Grid>
            </Grid>
          </Contenedor>
        </Contenedor>
      </Grid>
    </Contenedor>
  );
}

export default Contacto;
