// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import Contenedor from "components/Contenedor";
import TextSection from "components/TextSection";
import Button from "../Button";

const theme = createTheme();

theme.typography.h2 = {
  fontSize: "1.5rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.5rem",
  },
};

function RotatingCard({ image, title, description, action }) {
  const cardActionStyles = {
    display: "flex",
    alignItems: "center",
    width: "max-content",

    "& .material-icons, .material-icons-round,": {
      transform: `translateX(2px)`,
      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
    },

    "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
      {
        transform: `translateX(6px)`,
      },
  };
  return (
    <>
      <Contenedor
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="md"
        coloredShadow="dark"
        position="absolute"
        width="100%"
        height="100%"
        top={0}
        left={0}
        zIndex={1}
        sx={{
          backgroundImage: ({ palette: { black }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(rgba(black.main, 0.5), rgba(black.main, 0.4))}, url(${image})`,
          backgroundSize: "cover",
          backfaceVisibility: "hidden",
          p: 2,
          transform: "rotateY(180deg)",
        }}
      >
        <Contenedor pt={3} pb={2} px={2} textAlign="center" lineHeight={1}>
          <Contenedor>
            <ThemeProvider theme={theme}>
              <TextSection variant="h2" sx={{ color: "white", cardActionStyles }} gutterBottom>
                {title}
              </TextSection>
              <TextSection variant="h6" sx={{ color: "white" }} textAlign="justify">
                {description}
              </TextSection>
            </ThemeProvider>
          </Contenedor>
          {action && (
            <Contenedor maxWidth="18vh" mt={0} mb={-2} mx="auto">
              {action.type === "external" ? (
                <Button
                  component={MuiLink}
                  href={action.route}
                  target="_blank"
                  rel="noreferrer"
                  color="white"
                  size="small"
                  fullWidth
                >
                  {action.label}
                </Button>
              ) : (
                <Button component={Link} to={action.route} color="secondary" size="small" fullWidth>
                  {action.label}
                </Button>
              )}
            </Contenedor>
          )}
        </Contenedor>
      </Contenedor>
    </>
  );
}

// Typechecking props for the RotatingCard
RotatingCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ]).isRequired,
};

export default RotatingCard;
