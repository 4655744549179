import { Container, Grid } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Contenedor from "components/Contenedor";
import TextSection from "../TextSection";

const theme = createTheme();

theme.typography.h2 = {
  fontSize: "0.8rem",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
};
theme.typography.h5 = {
  fontSize: "0.6rem",
  "@media (min-width:600px)": {
    fontSize: "1rem",
  },
};

// eslint-disable-next-line react/prop-types
function Informativa({ img, title, text, color }) {
  const colors = {
    mainColor: color || "#ffffff",
    secondaryColor: color || "#ffffff",
  };
  return (
    <Contenedor>
      <Contenedor
        minHeight="100%"
        width="100%"
        sx={{
          backgroundImage: `url(${img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid display="flex">
            <Contenedor
              width="45%"
              sx={{
                backgroundImage: ({ functions: { linearGradient, rgba } }) =>
                  `${linearGradient(rgba(colors.mainColor, 0.7), rgba(colors.mainColor, 0.7))}`,
                backgroundSize: "cover",
              }}
              mt={{ xs: 0, md: 0, lg: 0 }}
              mx={{ xs: 0, lg: -8 }}
              py={{ xs: 5, lg: 18 }}
            >
              <Grid
                item
                xs={10}
                sm={10}
                md={10}
                lg={10}
                mx={{ xs: 1, lg: 5 }}
                justifyContent="center"
              >
                <Contenedor
                  position="relative"
                  sx={{
                    filter: "drop-shadow(0 0 0.1rem black)",
                  }}
                >
                  <ThemeProvider theme={theme}>
                    <TextSection
                      variant="h2"
                      textAlign="center"
                      sx={{ color: "#ffffff" }}
                      mt={{ xs: -2, sm: -4, md: -4, lg: -8 }}
                      mb={{ xs: 2, lg: 4 }}
                    >
                      {title}
                    </TextSection>
                    <TextSection
                      variant="h5"
                      sx={{ color: "#ffffff" }}
                      fontWeight="regular"
                      textAlign="justify"
                      mb={{ xs: -2, lg: 0 }}
                    >
                      {text}
                    </TextSection>
                  </ThemeProvider>
                </Contenedor>
              </Grid>
            </Contenedor>
          </Grid>
        </Container>
      </Contenedor>
    </Contenedor>
  );
}

export default Informativa;
