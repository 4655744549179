import React from "react";
import Contenedor from "components/Contenedor";
import { Grid, Container } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";
import TextSection from "../../../components/TextSection";
import Carrusel1 from "../../../assets/images/PURP_WEB/1.INICIO/RESPONSABILIDAD.jpg";
import Carrusel2 from "../../../assets/images/PURP_WEB/1.INICIO/CONFIANZA.jpg";
import Carrusel3 from "../../../assets/images/PURP_WEB/1.INICIO/INNOVACION.jpg";
import Carrusel4 from "../../../assets/images/PURP_WEB/1.INICIO/PASION.jpg";
import Carrusel5 from "../../../assets/images/PURP_WEB/1.INICIO/PROACTIVIDAD.jpg";

const theme = createTheme();

theme.typography.h1 = {
  fontSize: "1.2rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "2rem", // Ajusta el tamaño de fuente en pantallas 'sm' y más grandes
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem", // Ajusta el tamaño de fuente en pantallas 'md' y más grandes
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "3rem", // Ajusta el tamaño de fuente en pantallas 'lg' y más grandes
  },
};

const carruselItems = [
  {
    img: Carrusel1,
  },
  {
    img: Carrusel2,
  },
  {
    img: Carrusel3,
  },
  {
    img: Carrusel4,
  },
  {
    img: Carrusel5,
  },
];

/* eslint-disable react/prop-types */
const Carrusel = ({ text, palabras }) => (
  <Contenedor>
    <Carousel
      navButtonsAlwaysInvisible
      autoPlay
      stopAutoPlayOnHover={false}
      animation="fade"
      indicators
      duration={4000}
    >
      {carruselItems.map((item, index) => (
        <React.Fragment key={uuidv4()}>
          <Contenedor
            minHeight="60vh"
            maxHeight="100%"
            sx={{
              backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
                `${linearGradient(
                  rgba(gradients.dark.main, 0),
                  rgba(gradients.dark.state, 0)
                )}, url(${item.img})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Container>
              <Contenedor
                width="45%"
                sx={{
                  backgroundImage: ({ palette: { azul }, functions: { linearGradient, rgba } }) =>
                    `${linearGradient(rgba(azul.main, 0.6), rgba(azul.main, 0.6))}`,
                  backgroundSize: "cover",
                }}
                py={{ xs: 25, sm: 20, md: 20, lg: 30 }}
              >
                <Grid md={12} lg={12} textAlign="center">
                  <ThemeProvider theme={theme}>
                    <TextSection
                      sx={{ color: "#ffffff" }}
                      textAlign="center"
                      variant="h1"
                      fontWeight="regular"
                    >
                      {text}
                    </TextSection>
                    <TextSection
                      sx={{ color: "#ffffff" }}
                      textAlign="center"
                      variant="h1"
                      fontWeight="regular"
                    >
                      {palabras[index]}
                    </TextSection>
                  </ThemeProvider>
                </Grid>
              </Contenedor>
            </Container>
          </Contenedor>
        </React.Fragment>
      ))}
    </Carousel>
  </Contenedor>
);

export default Carrusel;
