import LanguageIcon from "@mui/icons-material/Language";

// eslint-disable-next-line import/no-cycle
import Nosotros from "pages/Secction/Nosotros";
// eslint-disable-next-line import/no-cycle
import Financiera from "pages/Secction/Financiera";
// eslint-disable-next-line import/no-cycle
import Informativa from "pages/Secction/Informativa";
// eslint-disable-next-line import/no-cycle
import Servicios from "pages/Secction/Granos";
// eslint-disable-next-line import/no-cycle
import Agronegocios from "pages/Secction/Agronegocios";
// eslint-disable-next-line import/no-cycle
import Aviso from "../pages/Principal/Secciones/AvisodePrivacidad";

const routes = [
  {
    name: "INICIO  |",
    route: "/principal",
  },
  {
    name: "NOSOTROS  |",
    route: "/pages/Nosotros",
    component: <Nosotros />,
  },
  {
    name: "GRANOS  |",
    route: "/pages/Servicios_y_granos",
    component: <Servicios />,
  },
  {
    name: "INSUMOS  |",
    route: "/pages/Insumos_agricolas",
    component: <Agronegocios />,
  },
  {
    name: "FINANCIERA  |",
    route: "/pages/Financiera_purp",
    component: <Financiera />,
  },
  {
    name: "INFORMATIVA  |",
    route: "/pages/Informativa",
    component: <Informativa />,
  },
  {
    name: "AVISO",
    route: "/AvisoPrivacidad",
    component: <Aviso />,
    notVisible: true,
  },
  {
    icon: <LanguageIcon />,
    collapse: [
      {
        name: "ENGLISH",
        route: "/principal",
      },
      {
        name: "中国人",
        route: "/principal",
      },
    ],
  },
];

export default routes;
