import { Card } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";

// Material Kit 2 React components
import Contenedor from "components/Contenedor";
import TextSection from "components/TextSection";

const theme = createTheme();

theme.typography.h2 = {
  fontSize: "1.5rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.5rem",
  },
};

function RotatingCardFront({ image, title }) {
  const cardActionStyles = {
    display: "flex",
    alignItems: "center",
    width: "max-content",

    "& .material-icons, .material-icons-round,": {
      transform: `translateX(2px)`,
      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
    },

    "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
      {
        transform: `translateX(6px)`,
      },
  };
  return (
    <>
      <Card>
        <Contenedor
          display="flex"
          justifyContent="center"
          alignContent="center"
          borderRadius="md"
          coloredShadow="black"
          width="100%"
          position="relative"
          zIndex={1}
          sx={{
            backgroundImage: ({ palette: { white }, functions: { linearGradient, rgba } }) =>
              `${linearGradient(rgba(white.main, 0.1), rgba(white.main, 0))}, url(${image})`,
            backgroundSize: "cover",
            p: 2,
          }}
        >
          <Contenedor
            py={{ xs: 5, sm: 8, md: 10, lg: 15, xxl: 18 }}
            px={2}
            textAlign="center"
            lineHeight={1}
            zIndex={1}
          >
            <ThemeProvider theme={theme}>
              <Contenedor
                sx={{ bgcolor: "#000000" }}
                width={150}
                opacity={0.4}
                py={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                px={{ xs: 0, lg: 0 }}
                mt={{ xs: 1, sm: 0, md: 0, lg: 0 }}
                zindex={1}
                position="relative"
              />
              <Contenedor position="relative" mt={{ xs: -3.5, sm: -3.5, md: -3.5, lg: -3.5 }}>
                <TextSection variant="h2" sx={{ color: "#ffffff", cardActionStyles }} gutterBottom>
                  {title}
                </TextSection>
              </Contenedor>
            </ThemeProvider>
          </Contenedor>
        </Contenedor>
      </Card>
    </>
  );
}

// Typechecking props for the RotatingCardFront
RotatingCardFront.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
};

export default RotatingCardFront;
