import Contenedor from "components/Contenedor";
import { Grid } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextSection from "../../../../components/TextSection";
import Icontext from "../../../../components/IconText";
import Check from "../../../../assets/images/PURP_WEB/2.NOSOTROS/CHECK_CAFE.png";

const theme = createTheme();

theme.typography.h2 = {
  fontSize: "0.9rem",
  "@media (min-width:600px)": {
    fontSize: "1.5rem",
  },
};
theme.typography.h6 = {
  fontSize: "0.7rem",
  "@media (min-width:600px)": {
    fontSize: "1rem",
  },
};
theme.typography.button = {
  fontSize: "0.7rem",
  "@media (min-width:600px)": {
    fontSize: "1rem",
  },
};

function NutricionVegetal() {
  return (
    <Contenedor>
      <Grid justifyContent="center" textAlign="center" mt={6} mb={4}>
        <ThemeProvider theme={theme}>
          <TextSection variant="h2" sx={{ color: "#5B524D" }}>
            NUTRICIÓN VEGETAL
          </TextSection>
        </ThemeProvider>
      </Grid>
      <Grid container justifyContent="center">
        <ThemeProvider theme={theme}>
          <Grid
            xs={5.5}
            lg={2.6}
            sx={{ margin: "0.5rem", "@media (min-width: 600px)": { margin: 0.5 } }}
          >
            <Contenedor>
              <Grid container>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pt={{ xs: 1, lg: 2.5 }}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#468203" }}>
                        UREA
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pb={1} pr={0}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#5B524D" }}>
                        FERTILIZANTE SÓLIDO
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
              </Grid>
            </Contenedor>
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Fertilizante granulado a base de
            46% de Nitrógeno"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 0 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Nomenclatura: 46 - 00 - 00"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Para granos y hortalizas"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
          </Grid>
          <Grid
            xs={5.5}
            lg={2.5}
            sx={{ margin: "0rem", "@media (min-width: 600px)": { margin: 0.5 } }}
          >
            <Contenedor>
              <Grid container>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pt={{ xs: 1, lg: 2.5 }}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#468203" }}>
                        MAP
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pb={1} pr={0}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#5B524D" }}>
                        FERTILIZANTE SÓLIDO
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
              </Grid>
            </Contenedor>
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Fertilizante granulado a base de
              11% de Nitrógeno y 52% Fósforo"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 0 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Nomenclatura: 11 - 52 - 00"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Para granos y hortalizas"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
          </Grid>
          <Grid
            xs={5.3}
            lg={3}
            mt={{ xs: 1, lg: 0 }}
            sx={{ margin: "0rem", "@media (min-width: 600px)": { margin: 0.5 } }}
          >
            <Contenedor>
              <Grid container>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pt={{ xs: 1, lg: 2.5 }}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#468203" }}>
                        SULFATO DE AMONIO
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pb={1} pr={0}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#5B524D" }}>
                        FERTILIZANTE SÓLIDO
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
              </Grid>
            </Contenedor>
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Fertilizante granulado a base de
              21% de Nitrógeno y 24% Azufre"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 0 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Nomenclatura: 21 - 00 - 00 - 24S"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Para granos"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Para suelos compactados"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
          </Grid>
          <Grid
            xs={5.5}
            lg={2}
            mt={{ xs: 1, lg: 0 }}
            sx={{ margin: "0rem", "@media (min-width: 600px)": { margin: 0.5 } }}
          >
            <Contenedor>
              <Grid container>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pt={{ xs: 1, lg: 2.5 }}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#468203" }}>
                        MEZCLA 3X1
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pb={1} pr={0}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#5B524D" }}>
                        MEZCLA FÍSICA
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
              </Grid>
            </Contenedor>
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Mezcla física balanceada
              con macro y micro elementos"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 0 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Para cultivos"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Muy apropiado para inicio
              de cultivos"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
          </Grid>
        </ThemeProvider>
      </Grid>
      <Grid container justifyContent="center" mt={{ xs: 0, lg: 6 }} mb={{ xs: 2, lg: 4 }}>
        <ThemeProvider theme={theme}>
          <Grid
            xs={5.5}
            lg={2.6}
            sx={{ margin: "0.5rem", "@media (min-width: 600px)": { margin: 0.5 } }}
          >
            <Contenedor>
              <Grid container>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pt={{ xs: 1, lg: 2.5 }}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#468203" }}>
                        N32
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pb={1} pr={0}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#5B524D" }}>
                        FERTILIZANTE LÍQUIDO
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
              </Grid>
            </Contenedor>
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Fertilizante líquido a base de 32%
              de Nitrógeno. Complejo químico
              Nitrogenado: Ureico, Amoniacal
              y Nítrico. De reacción ácida"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 0 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Nomenclatura: 32 - 00 - 00"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Para diversos cultivos"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
          </Grid>
          <Grid
            xs={5.5}
            lg={2.5}
            sx={{ margin: "0rem", "@media (min-width: 600px)": { margin: 0.5 } }}
          >
            <Contenedor>
              <Grid container>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pt={{ xs: 1, lg: 2.5 }}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#468203" }}>
                        MEZCLAS LÍQUIDAS
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pb={1} pr={0}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#5B524D" }}>
                        FERTILIZANTE LÍQUIDO
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
              </Grid>
            </Contenedor>
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Para cada etapa fenológica
              de cultivo"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 0 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
          </Grid>
          <Grid
            xs={5.3}
            lg={3}
            mt={{ xs: 1, lg: 0 }}
            sx={{ margin: "0rem", "@media (min-width: 600px)": { margin: 0.5 } }}
          >
            <Contenedor>
              <Grid container>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pt={{ xs: 1, lg: 2.5 }}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#468203" }}>
                        GAS AMONÍACO
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
                <Grid item xs={12} md={6} lg={12} sx={{ my: "auto" }}>
                  <Contenedor pb={1} pr={0}>
                    <ThemeProvider theme={theme}>
                      <TextSection variant="h6" sx={{ color: "#5B524D" }}>
                        FERTILIZANTE GASEOSO
                      </TextSection>
                    </ThemeProvider>
                  </Contenedor>
                </Grid>
              </Grid>
            </Contenedor>
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Fertilizante Nitrogenado al 82%"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0, lg: 0 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="NH3"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
            <Icontext
              color="#5B524D"
              imagen={Check}
              maxWidth="1rem"
              variante="button"
              Texto="Para cultivos"
              mt={{ xs: -0.7, lg: 0 }}
              pt={{ xs: 0.5, lg: 1 }}
              pb={0}
              px={{ xs: 0, lg: 0 }}
            />
          </Grid>
        </ThemeProvider>
      </Grid>
    </Contenedor>
  );
}
export default NutricionVegetal;
