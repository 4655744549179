import Grid from "@mui/material/Grid";
import Contenedor from "components/Contenedor";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ContenedorBloques from "../../components/ContendorBloques";
import TextSection from "../../../components/TextSection";

const theme = createTheme();

theme.typography.body2 = {
  fontSize: "0.75rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "0.99rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.1rem",
  },
};
/* eslint-disable react/prop-types */
export default function PorquePurp({ text }) {
  return (
    <Contenedor>
      <ContenedorBloques bgColor="#ffffff">
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          sx={{ mx: "auto" }}
          mt={{ xs: 0, md: 0, lg: 5 }}
          mb={{ xs: 1, md: 0, lg: 6 }}
        >
          <ThemeProvider theme={theme}>
            <TextSection variant="body2" sx={{ color: "#5B524D" }} textAlign="center" mt={2} mb={4}>
              {text}
            </TextSection>
          </ThemeProvider>
        </Grid>
      </ContenedorBloques>
    </Contenedor>
  );
}
